// Import necessary components and utilities
import { Field, Form, Formik } from "formik";
import ErrorBox from "../shared/ErrorBox";
import util from '../utils/util'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import useSignUp from "../functions/useSignUp";
import { useRef, useState } from "react";
import MaskedInput from 'react-text-mask';
import { SignUpStore } from "../stores/SignUpStore";
import ReCAPTCHA from 'react-google-recaptcha';
import LoadingModal from "./LoadingModal";
import { ANNUALY, MONTHLY } from "../shared/constants";



// Define SignUpForm component
const SignUpForm = () => {

    const { validateEmailForUse, phoneNumberMask, zipMask } = useSignUp();
    const [human, setHuman] = useState(true);
    const [loading, setLoading] = useState(false);

    const state = SignUpStore.useState(s => s);

    const formHidden = useRef(null);


    const verifyCallBack = (data) => {
        if (data !== '') {
            setHuman(true);
        }
    };


    const validateEmail = async (e) => {
        const validation = await validateEmailForUse(e, true)
        const spanholder = document.getElementById("emailInvalidChars");
        if (validation.result) {
            spanholder.classList.add("d-none");
        } else {
            spanholder.classList.remove("d-none");
            spanholder.innerHTML = validation.msg;
        }
    }

    const requestToken = async (values) => {
        try {
            setLoading(true);
            const fullValues = { ...values };
            let code = document.getElementById("discountInput").value;
            if (code !== "") {
                code = code.trim();
                fullValues.discount_code = code;
            }

            const selectedSubs = document.getElementById("idRadioMonthly").checked ? MONTHLY : ANNUALY;
            
            fullValues.subscriptionPlan = selectedSubs;
            const request = await util.axios.post( `/signup_converge`, {subscriberData: fullValues} );
            const { error, msg, token } = request.data;
            if (error) throw msg;
            document.getElementById("ssl_txn_auth_token").value = token;
            formHidden.current.submit();
        } catch (error) {
            setLoading(false);
            console.log( error );
        }
    }

    const url = ["production", "demo"].includes(process.env.REACT_APP_ENV.toLowerCase()) ? "https://api.convergepay.com/hosted-payments/": "https://api.demo.convergepay.com/hosted-payments/";



    return (
        <>

            {/* Formik form */}
            <Formik
                initialValues={util.initialValues}
                validationSchema={util.SignupSchema}
                onSubmit={async (values) => {
                    await requestToken(values);
                }}
            >
                {({
                    errors,
                    touched,
                    handleChange,
                }) => (
                    <Form>
                        {/* Form fields */}
                        <div className="container pt-3">
                            <h4><FontAwesomeIcon icon={faUserAlt} /> <span className="pl-2">Subscriber Information</span></h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <div><label htmlFor="firstNameId">First Name</label></div>
                                    <div><Field type="firstName" id="firstNameId" name="firstName" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="firstName" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="lastNameId">Last Name</label></div>
                                    <div><Field type="lastName" id="lastNameId" name="lastName" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="lastName" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="emailId">Email</label></div>
                                    <div>
                                        <Field onBlur={async (e) => {
                                            await validateEmail(e, true)
                                        }}
                                            type="email"
                                            id="emailId"
                                            name="email"
                                            className="form-control"
                                        />
                                    </div>
                                    <span className="d-none formError" id="emailInvalidChars"></span>
                                    <ErrorBox errors={errors} touched={touched} field="email" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="phoneNumberId">Phone Number</label></div>
                                    <div>
                                        <Field name="phoneNumber">
                                            {
                                                ({ field }) => <MaskedInput
                                                    {...field}
                                                    type="text"
                                                    id="phoneNumberId"
                                                    mask={phoneNumberMask}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                />
                                            }
                                        </Field>
                                        <ErrorBox errors={errors} touched={touched} field="phoneNumber" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div><label htmlFor="addressId">Address</label></div>
                                    <div><Field type="address" id="addressId" name="address" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="address" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="countryId">Country</label></div>
                                    <div>
                                        <Field as="select" type="country" id="countryId" name="country" className="form-control">
                                            <option value=""> -- </option>
                                            <option value="USA">USA</option>
                                        </Field>
                                    </div>
                                    <ErrorBox errors={errors} touched={touched} field="country" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="stateId">State</label></div>
                                    <div>
                                        <Field as="select" type="state" id="stateId" name="state" className="form-control">
                                            <option value=""> -- </option>
                                            {
                                                util.USA_STATES.map((item, index) => <option key={index} value={item.abbreviation}>{item.name}</option>)
                                            }
                                        </Field>

                                    </div>
                                    <ErrorBox errors={errors} touched={touched} field="state" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="cityId">City</label></div>
                                    <div><Field type="city" id="cityId" name="city" className="form-control" /></div>
                                    <ErrorBox errors={errors} touched={touched} field="city" />
                                </div>
                                <div className="col-md-6">
                                    <div><label htmlFor="zipId">Zip</label></div>
                                    <div>
                                        <Field name="zip">
                                            {
                                                ({ field }) => <MaskedInput
                                                    {...field}
                                                    type="text"
                                                    mask={zipMask}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                />
                                            }
                                        </Field>
                                    </div>
                                    <ErrorBox errors={errors} touched={touched} field="zip" />
                                </div>
                            </div>
                        </div>
                        {/* Submit button */}
                        <div className="d-flex justify-content-center mt-4">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA}
                                render="explicit"
                                onChange={verifyCallBack}
                            />
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="mt-4">
                                <p>
                                    <label className="termsCond" htmlFor="idTerms">
                                        <input id="idTerms" className="mr-2" type="checkbox" onChange={(e) => {
                                            SignUpStore.update(s => {
                                                s.acceptTerms = e.target.checked;
                                            })
                                        }} />
                                        Accept Terms and Conditions
                                    </label>
                                </p>
                                <p className="text-center">
                                    <a href="https://revrex.com/terms-of-use/" target="_blank" rel="noreferrer">Terms and Conditions</a>
                                </p>
                            </div>

                        </div>

                        <div className="mt-5 d-flex justify-content-center mb-3">
                            <button type="submit"
                                disabled={!state.acceptTerms}
                                className="btn btn-lg btnPay">
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                <span>Continue</span>
                            </button>
                        </div>

                    </Form>
                )}
            </Formik>
            <LoadingModal show={loading} handleClose={() => { }} />

            <form ref={formHidden} action={ url } method="POST" className="d-none" id="hiddenForm">
                <input type="hidden" name="ssl_txn_auth_token" id="ssl_txn_auth_token" />
                <button type="submit">Submit</button>
            </form>


        </>
    );
}

// Export SignUpForm component
export default SignUpForm;