import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ANNUALY, MONTHLY } from "../shared/constants";
import { SignUpStore } from "../stores/SignUpStore";
import util from "../utils/util";

const Sidebar = () => {

    // const state = SignUpStore.useState(s => s);

    // Function to validate the discount code
    const validateDiscountCode = async () => {
        try {
            // Check if the discount code is empty
            let code = document.getElementById("discountInput").value;
            if (code === "") return false;
            code = code.trim();
            document.getElementById("btnApplyDiscountCode").disabled = true;
            // Make an API request to validate the discount code
            const selectedSubs = document.getElementById("idRadioMonthly").checked ? MONTHLY : ANNUALY;
            const request = await util.axios.get(`/discount_code/validate/${code}/${selectedSubs}`);
            const { error, msg, result, data } = request.data;

            if (error) throw msg;
            document.getElementById("btnApplyDiscountCode").disabled = false;
            // CODE is valid
            resetUI()
            if (result) {
                switch (selectedSubs) {
                    // In monthly subscription we have different beahviours
                    case MONTHLY:
                        if (data.modifier === "month") {
                            // Modify price in sidebar
                            document.getElementById("monthlyAmount").innerText = util.currencyFormatter(data.price);
                            document.getElementById("totalId").innerText = "Total: " + util.currencyFormatter(161.1 + 55) + " USD";
                            document.getElementById("codeResultSuccess").classList.remove("d-none");
                            document.getElementById("codeResultSuccess").innerHTML = "The discount code is valid. <br> You will pay " + util.currencyFormatter(data.price) + " USD monthly";
                        }
                        if (data.modifier === "mappingfee") {
                            //Discount the Mappiing Fee and modify price in sidebar
                            document.getElementById("mappingFeeId").classList.add("text-decoration-line-through");
                            document.getElementById("totalId").innerText = "Total: " + util.currencyFormatter(179) + " USD";
                            document.getElementById("codeResultSuccess").innerHTML = "The discount code is valid. <br> We waive out the Mapping Fee";
                        }
                        if (data.modifier === "recurrent") {
                            // Normal price, but recurring fixed discount
                            document.getElementById("monthlyAmount").innerText = util.currencyFormatter(117);
                            document.getElementById("totalId").innerText = "Total: " + util.currencyFormatter(117+55) + " USD";
                            document.getElementById("codeResultSuccess").innerHTML = "The discount code is valid. <br> You will pay " + util.currencyFormatter(data.price) + " USD monthly";
                        }
                        break;
                    case ANNUALY:
                        console.log("Annualy");
                        break;
                    default:
                        console.log("Invalid subscription");
                }
                // Calculate the discount and update the store if the code is valid
                // const disc = state.selectedSubs === MONTHLY ? 179 - data.price : 2148 - data.price;
                document.getElementById("codeResultSuccess").classList.remove("d-none");

            } else {
                // Handle invalid discount code, reset all amounts

                document.getElementById("codeResultFail").classList.remove("d-none");
                SignUpStore.update(s => {
                    s.isValid = result;
                    //   s.total = calculateTotal(s.selectedSubs, 0);
                    s.discount = 0;
                });
            }
        } catch (error) {
            // Handle API request errors
            document.getElementById("btnApplyDiscountCode").disabled = false;
            alert(JSON.stringify(error));
        }
    };

    const resetUI = () => {
        const selectedSubs = document.getElementById("idRadioMonthly").checked ? MONTHLY : ANNUALY;
        document.getElementById("codeResultFail").classList.add("d-none");
        document.getElementById("codeResultSuccess").classList.add("d-none");
        document.getElementById("mappingFeeId").classList.remove("text-decoration-line-through");
        if (selectedSubs === ANNUALY) {
            document.getElementById("totalId").innerText = "Total: " + util.currencyFormatter(2203) + " USD";
        } else {
            document.getElementById("monthlyAmount").innerText = util.currencyFormatter(179);
            document.getElementById("totalId").innerText = "Total: " + util.currencyFormatter(234) + " USD";
        }

    }

    // Function to handle the change of subscription
    const handleSubscriptionChange = (e) => resetUI()

    // Function to handle code input changes
    const handleCodeChange = (e) => {
        const code = e.target.value;
        if (code === "") {
            // Reset code, discount, and total when the code is empty
            resetUI()
        }
    }


    return (
        <>
            <h1 className="p-3 mt-3 text-center sidebar_title text-white">
                Order Summary
            </h1>
            <div className="p-3">
                <ul className="subscriptionOptions">
                    <p className="sidebar_subtitle" >Select Subscription</p>
                    <li>
                        <div className={`pl-2 pr-2 radioOption  d-flex justify-content-between `}>
                            <div className="d-flex align-items-center w-100">
                                <input defaultChecked type="radio" name="subscriptionLenght" onChange={handleSubscriptionChange} id="idRadioMonthly" value="1" />
                                <label htmlFor="idRadioMonthly">
                                    <span className="p-2">Monthly</span>
                                </label>
                            </div>
                            <label htmlFor="idRadioMonthly">
                                <div id="monthlyAmount" className="pl-1">$179.00</div>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div className={`pl-2 pr-2 radioOption  d-flex justify-content-between `}>
                            <div className="d-flex align-items-center">
                                <input type="radio" name="subscriptionLenght" onChange={handleSubscriptionChange} id="idRadioAnnually" value="2" />
                                <label htmlFor="idRadioAnnually"><span className="p-2">Annually</span></label>
                            </div>
                            <label htmlFor="idRadioAnnually">
                                <div id="annuallyAmount" className="pl-1">$2,148.00</div>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div id="mappingFeeId" className="radioOption  d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faCheck} />
                                <span className="p-2">
                                    <span className="pr-1 false" aria-label="One Time Bank Mapping Fee">Bank Mapping Fee</span>
                                </span>
                            </div>
                            <label htmlFor="idRadioAnnually" className="pr-1 false">
                                <div className="pl-1">$55.00</div>
                            </label>
                        </div>
                    </li>
                    <li>
                        <div className=" mt-5">
                            <div className="text-center mb-3"><span>Enter Disccount Code</span></div>
                            <div className="pr-1">
                                <div className="d-flex align-items-center">
                                    <input id="discountInput" onChange={handleCodeChange} maxLength="6" type="text" className="form-control text-center" />
                                    <div>
                                        <button id="btnApplyDiscountCode" onClick={validateDiscountCode} className="m-1 btn btn-md btn-primary">
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2 d-none text-danger text-center" id="codeResultFail">
                                The discount code is not valid
                            </div>
                            <div className="pt-2 d-none text-success text-center" id="codeResultSuccess">
                                The discount code is valid. Discount applied.
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div id="totalId" className="text-center sidebar_total goldColor">
                Total: $234.00 USD
            </div>
            <div className="dividerLine"></div>
            <div className="d-grid">
            </div>
        </>
    );
}

export default Sidebar;

