import axios from 'axios';
import * as Yup from 'yup';

const util = {};

util.axios = axios.create({
  baseURL: process.env.REACT_APP_REVREX_API_URL,
  timeout: 120000,
});

util.initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  address: "",
  country: "USA",
  city: "",
  state: "",
  zip: "",
}

util.initialValuesACH = {
  accountNumber: "",
  routingNumber: "",
  accountType: "",
}

Yup.addMethod(Yup.string, "phoneLenght", function (errorMessage) {
  return this.test(`test-phone-length`, errorMessage, function (value) {
    const { path, createError } = this;
    // const formatted = value.replace( /_/g, '' );
    const formatted = value.replace(/[(_-\s)]/g, '');
    return (
      (formatted && formatted.length === 10 /*14*/) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "zipLenght", function (errorMessage) {
  return this.test(`test-phone-length`, errorMessage, function (value) {
    const { path, createError } = this;
    const formatted = value?.replace(/^\d{5}$/g, '');
    return (
      (formatted && formatted.length === 5) ||
      createError({ path, message: errorMessage })
    );
  });
});

util.SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .max(50, "First name must be max 50 characters")
    .min(2, "First name must be at least 2 characters"),
  lastName: Yup.string().required('Last name is required')
  .max(50, "Last name must be max 50 characters")
    .min(2, "Last name must be at least 2 characters"),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required').phoneLenght("Invalid phone number"),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required').matches(/^[0-9]+$/, "Must be only digits"),
});

util.ACHSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Account Number is required'),
  routingNumber: Yup.string().required('Routing Number is required'),
  accountType: Yup.string().required('Account Type is required'),
});

util.USA_STATES = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PALAU', abbreviation: 'PW' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' }
]

const createYearsExpiration = () => {
  const currentYear = parseInt(new Date().toISOString().split("T")[0].split("-")[0])
  const years = []
  years.push(currentYear)
  for (let x = 0; x < 10; x++) {
    years.push(years[x] + 1)
  }
  return years
}

util.currencyFormatter = (amount = null) => {
  if (amount === null) return null
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
}

util.EXPIRATION_YEARS = createYearsExpiration()

util.EXPIRATION_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']


export default util